<template>
  <div>
    <div :class="animation">
      <b-card
        title="Validation KYC"
      >
        <b-form @submit.prevent="tryToSave">
          <b-row>
            <b-col sm="6">
              <b-form-group
                label="Document à fournir"
                :description="description"
              >
                <b-form-select
                  v-model="$v.currentDocument.$model"
                  :options="documentOptions"
                  :class="{
                    'is-invalid': $v.currentDocument.$error,
                  }"
                  plain
                />
                <div
                  v-if="$v.currentDocument.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.currentDocument.required">{{
                    $t("errors.required")
                  }}</span>
                </div>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group label="Fichier">
                <b-form-file
                  v-model="$v.file.$model"
                  browse-text="Parcourir"
                  placeholder="Choisir le fichier"
                  :file-name-formatter="formatNames"
                  :disabled="this.$v.currentDocument.$invalid"
                  accept=".jpg, .png"
                  :class="{'is-invalid':$v.file.$error}"
                  @change="uploadFile($event)"
                />
                <b-progress
                  v-if="loadImage"
                  variant="primary"
                  height="3px"
                  :value="progress"
                  :max="100"
                  class="w-100 mt-1"
                />
                <div
                  v-if="this.$v.file.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.file.required">L'image est obigatoire<br></span>
                  <span v-if="!$v.file.checkSize">Taille maximale: 5MB</span>
                </div>
              </b-form-group>
            </b-col>

            <b-col>
              <div class="mt-3">
                <div class="float-right">
                  <b-button
                    type="submit"
                    variant="outline-success"
                    :disabled="loadImage"
                  >
                    Suivant
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </div>
    <b-modal
      v-if="showModal"
      v-model="showModal"
      :title="`${titleName}`"
      centered
      hide-footer
      :hide-header-close="process"
      :no-close-on-esc="process"
      :no-close-on-backdrop="process"
    >
      <div class="text-center">
        <a
          :href="currentDocumentStorage.dataUrl"
          target="_blank"
        >
          Cliquer pour voir le fichier avant soumission
        </a>
      </div>
      <hr class="my-3 bg-primary">
      <div
        v-if="process"
        class="d-flex justify-content-center"
      >
        <b-spinner
          variant="primary"
          label="Loading..."
        />
      </div>
      <div class="mt-3">
        <div class="float-left">
          <b-button
            variant="outline-danger"
            @click="closeModal"
          >
            Fermer
          </b-button>
        </div>
        <div class="float-right">
          <b-button
            variant="outline-success"
            @click="tryToSubmit"
          >
            Soumettre
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import firebase from 'firebase/app'
import { v4 as uuidv4 } from 'uuid'
import encrypt from '@/libs/encrypt'
import 'firebase/storage'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      showModal: false,
      animation: null,
      documents: [],
      loadImage: false,
      progress: 0,
      description: null,
      titleName: null,
      docs: [],
      currentDocument: null,
      file: null,
      currentDocumentStorage: null,
    }
  },
  validations: {
    file: {
      required,
      checkSize() {
        if (this.file != null) {
          const filesize = this.file.size
          const file = Math.round(filesize / 1024 / 1024)
          if (file <= 5) {
            return true
          }
          return false
        }
        return ''
      },
    },
    currentDocument: {
      required,
    },
    currentDocumentStorage: {
      required,
    },
  },
  computed: {
    documentOptions() {
      const options = this.documents
      const authOptions = []
      // eslint-disable-next-line no-restricted-syntax
      for (const i in this.docs) {
        if (this.docs[i].state === 'VALID' || this.docs[i].state === 'TREATMENT' || this.docs[i].state === 'SUBMITTED') {
          authOptions.push(this.docs[i].doc_type)
        }
      }
      const data = [
        {
          value: null,
          description: null,
          text: 'Chosir le document',
          disabled: true,
        },
      ]
      // eslint-disable-next-line no-restricted-syntax
      for (const item in options) {
        if (options[item].id) {
          const current = {
            value: options[item].doc_type,
            text: options[item].name,
            description: options[item].description,
            disabled: authOptions.includes(options[item].doc_type),
          }
          data.push(current)
        }
      }
      return data
    },
    ...mapGetters({
      process: 'newKycDocumentProcess',
      success: 'newKycDocumentSuccess',
      error: 'newKycDocumentError',
    }),
  },
  watch: {
    currentDocument(val) {
      if (val) {
        this.description = this.documentOptions.filter(item => item.value === val)[0].description
        this.titleName = this.documentOptions.filter(item => item.value === val)[0].text
      }
    },
    progress(val) {
      if (val === 100) {
        this.loadImage = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Upload',
            icon: 'CheckCircleIcon',
            text: 'Fichier uploadé avec succès.',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    success(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Validation KYC',
            text: 'Document soumis avec succès.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push('/admin-kyc-listing')
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Validation KYC',
            text: "Une erreur est survenue lors de l'enrégistrement.",
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  mounted() {
    if (!this.$store.getters.currentUser.isUpdate) {
      this.$router.push('/account-settings')
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Mise a jour du Profil',
          text: 'Veuillez mettre a jour votre profil avant de valider votre compte.',
          icon: 'CheckCircleIcon',
          variant: 'warning',
        },
      })
    } else {
      this.getAllDocuments()
      this.getAllRequiredDocuments()
    }
  },
  methods: {
    ...mapActions(['newKycDocument']),
    async getAllRequiredDocuments() {
      this.documents = await this.$http
        .get('/required-kyc-doc/all', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => res.data)
        .catch(() => [])
    },
    getDocumentName(item) {
      let name = null
      switch (item) {
        case 'ID':
          name = "PIECE D'IDENTITE"
          break
        case 'FACE_ID':
          name = 'SELFIE'
          break
        default:
          break
      }
      return name
    },
    openModal() {
      this.showModal = true
    },
    closeModal() {
      this.showModal = false
    },
    async getAllDocuments() {
      this.docs = await this.$http
        .get(`/user-kyc-doc/find-by-user/${this.$store.getters.currentUser.id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => res.data)
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Documents a fournir',
              text: 'Une erreur est survenue.',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
    async uploadFile(event) {
      this.$v.currentDocument.$touch()
      if (this.$v.currentDocument.$anyError) return
      this.currentDocumentStorage = null
      const document = Object.values(event.target.files)[0]
      let folder = null
      switch (this.currentDocument) {
        case 'FACE_ID':
          folder = 'SELFIE'
          break
        case 'ID':
          folder = 'POI'
          break
        default:
          break
      }
      this.loadImage = true
      const self = this
      const storageRef = firebase.storage().ref(`${folder}/${encrypt.encode(this.$store.getters.currentUser.email)}-${uuidv4()}`)
      // Upload file to Firebase Storage
      const uploadTask = storageRef.put(document)
      uploadTask.on('state_changed', snapshot => {
        self.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        if (self.progress < 100) {
          self.loadImage = true
        }
      }, () => {
        this.loadImage = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Upload',
            icon: 'XCircleIcon',
            text: 'Une erreur est survenue lors du téléchargement du fichier.',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      },
      () => {
        uploadTask.snapshot.ref.getMetadata()
          .then(data => {
            uploadTask.snapshot.ref.getDownloadURL().then(url => {
              this.currentDocumentStorage = {
                name: data.name,
                type: data.contentType,
                dataUrl: url,
              }
            })
            this.loadImage = false
          })
      })
    },
    formatNames(files) {
      let name = null
      if (files.length === 1) {
        name = '1 fichier selectonné'
      }
      return name
    },
    tryToSave() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        this.animation = 'animate-wrong'
        setTimeout(() => {
          this.animation = null
        }, 500)
        return
      }
      this.openModal()
    },
    tryToSubmit() {
      this.newKycDocument({
        doc_uri: this.currentDocumentStorage.dataUrl,
        file_type: this.currentDocumentStorage.type,
        name: this.currentDocumentStorage.name,
        doc_type: this.currentDocument,
      })
    },
  },
}
</script>

<style>
.animate-wrong {
  animation: wrong 0.4s ease-in-out;
}

@keyframes wrong {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(40px);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    transform: translateX(40px);
  }
  80% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
